<template>
  <div class="hashrate-style">
    <titles :title="$t('text.t535')" />
    <div class="box1">
      <div class="list start-center">
        <div class="item center-center" :class="active==item.chain_type?'acitem':''" v-for="item in chain_list" :key="item.chain_type" @click="changeActive(item.chain_type)">
          <img :src="item.chain_logo" class="item-i1" />
          <div class="t1">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="box2">
      <img :src="topImage" class="b2-i1" />
    </div>
    <div class="box3" v-if="active == 0">
      <el-card class="box-card">
        <div class="top between-center">
          <div class="t1">{{$t('text.t536')}}</div>
          <div class="btn" @click="participate('/exchange')">{{$t('text.t474')}}</div>
        </div>
        <!-- <div class="bot">
        </div> -->
      </el-card>
      <!-- <el-card class="box-card">
        <div class="top between-center">
          <div class="t1">{{$t('text.t537')}}</div>
          <div class="btn" @click="participate('/synthesis')">{{$t('text.t474')}}</div>
        </div>
      </el-card> -->
      <el-card class="box-card">
        <div class="top between-center">
          <div class="t1">{{$t('text.t538')}}</div>
          <div class="btn" @click="participate('/pledge')">{{$t('text.t474')}}</div>
        </div>
        <!-- <div class="bot">
          {{$t('text.t475')}}
        </div> -->
      </el-card>
      <el-card class="box-card">
        <div class="top between-center">
          <div class="t1">{{$t('text.t539')}}</div>
          <div class="btn" @click="participate('/lpPlege')">{{$t('text.t474')}}</div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="top between-center">
          <div class="t1">{{$t('text.t283')}}</div>
          <div class="btn" @click="goOutLink">{{$t('text.t474')}}</div>
        </div>
        <!-- <div class="bot">
        </div> -->
      </el-card>
      <el-card class="box-card">
        <div class="top between-center">
          <div class="t1">{{$t('text.t12')}}</div>
          <div class="btn" @click="participate('/globalnode')">{{$t('text.t474')}}</div>
        </div>
        <!-- <div class="bot">
        </div> -->
      </el-card>
    </div>
  </div>
</template>

<script>
import titles from "@/components/titles/index.vue";
import {mapState} from 'vuex';
export default {
  components:{
    titles
  },
  data(){
    return{
      active:1,
      topImage:''
    }
  },
  created(){
    this.getSwiper()
  },
  computed:{
    ...mapState(["language","chainId","config","chain_list"])
  },
  methods:{
    getSwiper(){
      this.$http.get('/index/swiperList',{
        page:1,
        limit:1,
        language:this.language
      }).then(res=>{
        this.topImage = this.$http.imgurl + res.data.data[0].image
      })
    },
    changeActive(id){
      this.active = id
    },
    participate(url){
      if(this.chainId=='0x2d20'){
        this.goAhead(url)
      } else{
        this.$toast(this.$t('text.t477'))
      }
    },
    goOutLink(){
      window.location.href = 'http://transfer.trueshop.top/'
    },
  }
}
</script>

<style lang="less">
.hashrate-style{
  .box1{
    padding: 20px 0 10px;
    overflow: auto;
    .list{
      padding: 0 20px;
      .item{
        flex-shrink: 0;
        background: #FFFFFF;
        border: 1px solid #323232;
        border-radius: 40px;
        padding: 7px 15px;
        margin-right: 25px;
        opacity: 0.5;
        .item-i1{
          width: 25px;
          height: 25px;
          margin-right: 15px;
        }
        .t1{
          font-size: 12px;
          font-weight: bold;
          color: #323232;
        }
      }
      .acitem{
        opacity: 1;
      }
    }
  }
  .box2{
    width: 100%;
    .b2-i1{
      width: 100%;
      height: auto;
    }
  }
  .box3{
    padding: 20px 10px;
    .el-card__body, .el-main{
      padding: 0;
    }
    .box-card{
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
      border: 1px solid #E1E1E1;
      .top{
        padding: 12px 22px 12px 18px;
        border-bottom: 1px solid #E1E1E1;
        .t1{
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .btn{
          box-shadow: 2px -2px 0 #ccc;
          padding: 7px 14px;
          background: #323232;
          font-size: 12px;
          font-weight: bold;
          color: #FFFFFF;
          border-radius: 50px;
        }
      }
      .bot{
        padding: 20px 18px;
        font-size: 12px;
        font-weight: 500;
        color: #323232;
      }
    }
  }
}
</style>